import type { FC } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
	SPACE_PAGES_FILTER_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { getApolloClient } from '@confluence/graphql';
import { getSingleParam, RoutesContext } from '@confluence/route-manager';
import type { PageCardAppearance } from '@confluence/page-card';
import { useSessionData } from '@confluence/session-data';
import { usePageSpaceKey } from '@confluence/page-context';

import type { ConfluenceContentSortField as SortBy } from './__types__/SpacePagesQuery';
import {
	ConfluenceContentSortingParameter as SortField,
	SortOrder,
} from './__types__/SpacePagesQuery';
import {
	SpacePagesAppearanceMutation,
	SpacePagesSortMutation,
	SpacePagesPersistenceQuery,
} from './SpacePagesPersistenceQueries.graphql';
import type {
	SpacePagesPersistenceQuery as SpacePagesPersistenceQueryData,
	SpacePagesPersistenceQuery_userPreferences_spacePagesSortView as PagesSortPersistenceOption,
	SpacePagesPersistenceQueryVariables as SpacePagesPersistenceQueryVars,
} from './__types__/SpacePagesPersistenceQuery';
import {
	PagesDisplayPersistenceOption,
	PagesSortField,
	PagesSortOrder,
} from './__types__/SpacePagesPersistenceQuery';
import type {
	SpacePagesAppearanceMutation as SpacePagesAppearanceMutationData,
	SpacePagesAppearanceMutationVariables as SpacePagesAppearanceMutationVars,
} from './__types__/SpacePagesAppearanceMutation';
import type { ParsedUrlQuery } from './types';
import type {
	SpacePagesSortMutation as SpacePagesSortMutationData,
	SpacePagesSortMutationVariables as SpacePagesSortMutationVars,
} from './__types__/SpacePagesSortMutation';

const QueryParamKeys = {
	TEXT_FILTER: 'text',
	PAGES_FILTER: 'pagesFilter',
	SORT_FIELD: 'sortField',
	SORT_ORDER: 'sortOrder',
	APPEARANCE: 'view',
};

export enum PagesFilter {
	all = 'all',
	workedOn = 'worked-on',
	starred = 'starred',
}

export type Filters = {
	text: string;
	pagesFilter: PagesFilter;
};

type SetFilter = (filter: Partial<Filters>) => void;
type SetSortBy = (sortBy?: SortBy) => void;
type SetAppearance = (appearance: PageCardAppearance) => void;

type SpacePagesContextType = {
	filters: Filters;
	setFilter: SetFilter;
	sortBy?: SortBy;
	setSortBy: SetSortBy;
	appearance: PageCardAppearance;
	setAppearance: SetAppearance;
	persistenceQueryError?: Object;
	persistenceQueryLoading: boolean;
};

const defaultSpacePagesContext: SpacePagesContextType = {
	filters: {
		text: '',
		pagesFilter: PagesFilter.all,
	},
	setFilter: () => {},
	sortBy: {
		field: SortField.LAST_MODIFIED_DATE,
		order: SortOrder.DESC,
	},
	setSortBy: () => {},
	appearance: 'grid',
	setAppearance: () => {},
	persistenceQueryError: undefined,
	persistenceQueryLoading: false,
};

const getFilters: (queryParams: ParsedUrlQuery) => Filters = (queryParams) => {
	const text = getSingleParam(queryParams, QueryParamKeys.TEXT_FILTER) || '';
	const pagesFilterParam = getSingleParam(queryParams, QueryParamKeys.PAGES_FILTER) as PagesFilter;
	const pagesFilter = Object.values(PagesFilter).includes(pagesFilterParam)
		? pagesFilterParam
		: PagesFilter.all;
	return {
		text,
		pagesFilter,
	};
};

export const hasFilters = (filters: Filters): boolean =>
	Boolean(filters.text || filters.pagesFilter !== 'all');

const getSortBy: (queryParams: ParsedUrlQuery) => SortBy | undefined = (queryParams) => {
	const field = getSingleParam(queryParams, QueryParamKeys.SORT_FIELD);
	const order = getSingleParam(queryParams, QueryParamKeys.SORT_ORDER);
	if (field && order && field in SortField && order in SortOrder) {
		return {
			field,
			order,
		} as SortBy;
	}

	return {
		field: SortField.LAST_MODIFIED_DATE,
		order: SortOrder.DESC,
	};
};

const getPersistedSortBy = (sort: PagesSortPersistenceOption): SortBy | undefined => {
	const field = sort?.field;
	const order = sort?.order;

	if (field && order && field in SortField && order in SortOrder) {
		return {
			field,
			order,
		} as unknown as SortBy;
	}
};

const convertPageSortByToPersistenceOption = (sort) => {
	const sortField = sort?.field;
	const sortOrder = sort?.order;
	let field: PagesSortField = PagesSortField.LAST_MODIFIED_DATE;
	let order: PagesSortOrder = PagesSortOrder.DESC;
	for (const value in PagesSortField) {
		if (sortField === value) {
			field = PagesSortField[value] as PagesSortField;
		}
	}
	for (const value in PagesSortOrder) {
		if (sortOrder === value) {
			order = PagesSortOrder[value] as PagesSortOrder;
		}
	}
	if (sortField === undefined && sortOrder === undefined) {
		field = PagesSortField['RELEVANT'] as PagesSortField;
		order = PagesSortOrder['DESC'] as PagesSortOrder;
	}
	return {
		field,
		order,
	} as PagesSortPersistenceOption;
};

const getAppearance = (appearance: string | null): PageCardAppearance => {
	return appearance === PagesDisplayPersistenceOption.LIST
		? 'list'
		: appearance === PagesDisplayPersistenceOption.COMPACT_LIST
			? 'compact-list'
			: 'grid';
};

const convertPageCardAppearanceToPersistenceOption = (appearance: PageCardAppearance) => {
	if (appearance === 'list') {
		return PagesDisplayPersistenceOption.LIST;
	}
	if (appearance === 'grid') {
		return PagesDisplayPersistenceOption.CARDS;
	}
	if (appearance === 'compact-list') {
		return PagesDisplayPersistenceOption.COMPACT_LIST;
	}
};

export const SpacePagesContext = createContext<SpacePagesContextType>(defaultSpacePagesContext);

/*
 * SpacePagesContextProvider contains the logic for accessing and updating
 * filters and other user options. The state is stored within query params
 * however, updating query params causes most of the app to rerender and leads
 * to noticeable lag in the UI. Because of this, the component reads the query
 * params initially and stores them into local state which is then passed into
 * the context provider. On every update, first the state is updated to avoid
 * any lag, then, once that is complete, the query params are updated. This
 * is achieved using setTimeout(() => {...}, 0).
 *
 * This solution is a hack and should be cleaned up in the future.
 */
export const SpacePagesContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { getQueryParams, setQueryParams } = useContext(RoutesContext);
	const [stateSpaceKey] = usePageSpaceKey();
	// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
	const spaceKey: string = stateSpaceKey;
	const experienceTracker = useContext(ExperienceTrackerContext);
	const queryParams = getQueryParams();
	const { isLoggedIn } = useSessionData();

	const {
		data: persistenceQueryData,
		loading: persistenceQueryLoading,
		error: persistenceQueryError,
	} = useQuery<SpacePagesPersistenceQueryData, SpacePagesPersistenceQueryVars>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePagesPersistenceQuery,
		{
			skip: !isLoggedIn,
			variables: {
				spaceKey,
			},
		},
	);

	const [filtersState, setFiltersState] = useState<Filters>(() => getFilters(queryParams));
	const [sortByState, setSortByState] = useState<SortBy | undefined>(() => getSortBy(queryParams));

	const [appearanceState, setAppearanceState] = useState<PageCardAppearance>(() =>
		getAppearance(getSingleParam(queryParams, QueryParamKeys.APPEARANCE) || null),
	);

	useEffect(() => {
		if (persistenceQueryData) {
			setAppearanceState(
				getAppearance(
					persistenceQueryData?.userPreferences?.globalPageCardAppearancePreference || null,
				),
			);
			setSortByState(
				getPersistedSortBy(persistenceQueryData?.userPreferences?.spacePagesSortView || null),
			);
		}
	}, [persistenceQueryData]);

	const [updatePersistedSortBy] = useMutation<
		SpacePagesSortMutationData,
		SpacePagesSortMutationVars
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePagesSortMutation,
	);

	const [updatePersistedAppearance] = useMutation<
		SpacePagesAppearanceMutationData,
		SpacePagesAppearanceMutationVars
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpacePagesAppearanceMutation,
	);

	const setFilter: SetFilter = useCallback(
		(filter) => {
			setFiltersState((prevState) => ({ ...prevState, ...filter }));
			experienceTracker.start({
				name: SPACE_PAGES_FILTER_EXPERIENCE,
				attributes: { filterType: filter.text ? 'text' : 'worked-on' },
			});
			setTimeout(() => {
				const filterQueryParams = {};
				Object.keys(filter).forEach((key) => {
					if (key === 'pagesFilter' && filter[key] === 'all') {
						filterQueryParams[key] = undefined;
					} else {
						filterQueryParams[key] = filter[key] || undefined;
					}
				});
				setQueryParams(filterQueryParams, true);
			}, 0);
		},
		[setQueryParams, experienceTracker],
	);

	const setSortByForAnonymousUsers: SetSortBy = useCallback(
		(sort) => {
			setSortByState(sort);
			setTimeout(() => {
				setQueryParams(
					{
						[QueryParamKeys.SORT_FIELD]: sort?.field,
						[QueryParamKeys.SORT_ORDER]: sort?.order,
					},
					true,
				);
			}, 0);
		},
		[setQueryParams],
	);

	const setSortBy: SetSortBy = useCallback(
		(sort) => {
			const sortOption = convertPageSortByToPersistenceOption(sort);
			experienceTracker.start({
				name: SPACE_PAGES_FILTER_EXPERIENCE,
				attributes: { filterType: 'sort' },
			});

			void updatePersistedSortBy({
				variables: {
					spaceKey,
					field: sortOption ? sortOption.field : PagesSortField.LAST_MODIFIED_DATE,
					order: sortOption ? sortOption.order : PagesSortOrder.DESC,
				},
			});

			// Update the cache to keep UI updated regardless of whether the mutation succeeds
			getApolloClient().writeQuery({
				query: SpacePagesPersistenceQuery,
				variables: { spaceKey },
				data: {
					userPreferences: {
						globalPageCardAppearancePreference:
							convertPageCardAppearanceToPersistenceOption(appearanceState),
						spacePagesSortView: {
							field: sortOption.field,
							order: sortOption.order,
							__typename: 'PagesSortPersistenceOption',
						},
						__typename: 'UserPreferences',
					},
				},
			});
		},
		[spaceKey, experienceTracker, updatePersistedSortBy, appearanceState],
	);

	const setAppearanceForAnonymousUsers: SetAppearance = useCallback(
		(appearance) => {
			setAppearanceState(appearance);
			setTimeout(() => {
				setQueryParams({ [QueryParamKeys.APPEARANCE]: appearance }, true);
			}, 0);
		},
		[setQueryParams],
	);

	const setAppearance: SetAppearance = useCallback(
		(appearance: PageCardAppearance) => {
			const appearanceOption = convertPageCardAppearanceToPersistenceOption(appearance);

			updatePersistedAppearance({
				variables: {
					persistenceOption: appearanceOption || PagesDisplayPersistenceOption.CARDS,
				},
			}).catch(() => {}); // ignoring errors here because we are consuming error from useMutation

			const sortOption = convertPageSortByToPersistenceOption(sortByState);
			// Update the cache to keep UI updated regardless of whether the mutation succeeds
			getApolloClient().writeQuery({
				query: SpacePagesPersistenceQuery,
				variables: { spaceKey },
				data: {
					userPreferences: {
						globalPageCardAppearancePreference: appearanceOption,
						spacePagesSortView: {
							field: sortOption?.field,
							order: sortOption?.order,
							__typename: 'PagesSortPersistenceOption',
						},
						__typename: 'UserPreferences',
					},
				},
			});
		},
		[spaceKey, updatePersistedAppearance, sortByState],
	);

	const spacePagesContextValue = useMemo(
		() => ({
			filters: filtersState,
			setFilter,
			sortBy: sortByState,
			setSortBy: isLoggedIn ? setSortBy : setSortByForAnonymousUsers,
			appearance: appearanceState,
			setAppearance: isLoggedIn ? setAppearance : setAppearanceForAnonymousUsers,
			persistenceQueryError,
			persistenceQueryLoading,
		}),
		[
			filtersState,
			sortByState,
			appearanceState,
			isLoggedIn,
			setFilter,
			setSortBy,
			setSortByForAnonymousUsers,
			setAppearance,
			setAppearanceForAnonymousUsers,
			persistenceQueryError,
			persistenceQueryLoading,
		],
	);

	return (
		<SpacePagesContext.Provider value={spacePagesContextValue}>
			{children}
		</SpacePagesContext.Provider>
	);
};
